import { ViewValue } from '@resparke/models';

export enum FormControls {
  SELECT = 'SELECT',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  TEXTAREA = 'TEXTAREA',
  TEXT = 'TEXT',
  DATE = 'DATE',
  AUTOCOMPLETE_CHIP = 'AUTOCOMPLETE_CHIP',
  CHIP_INPUT = 'CHIP_INPUT',
  TOGGLE = 'TOGGLE',
  CHIPS = 'CHIPS',
  TEXTAREA_ADD = 'TEXTAREA_ADD',
  GRID_ADD = 'GRID_ADD',
  IMAGE = 'IMAGE',
  HIDDEN = 'HIDDEN',
}

export interface BaseField {
  label?: string;
  name: string;
  type: FormControls;
  defaultValue?: any;
  required?: boolean;
  disableEdit?: boolean;
  hideError?: boolean;
  subscriptSizing?: 'dynamic' | 'static';
  appearance?: 'fill' | 'outline';
}

export interface SelectField extends BaseField {
  type: FormControls.SELECT;
  options: ViewValue[];
}

export interface TextField extends BaseField {
  type: FormControls.TEXT;
}

export interface TextArea extends BaseField {
  type: FormControls.TEXTAREA;
  rows: number;
}
export interface TextAreaAdd extends BaseField {
  type: FormControls.TEXTAREA_ADD;
  rows: number;
  defaultValue: string[];
}

export interface DateField extends BaseField {
  type: FormControls.DATE;
}
export interface AutocompleteField extends BaseField {
  type: FormControls.AUTOCOMPLETE;
  options: ViewValue[];
  creatable?: boolean;
  icon?: string;
}

export interface AutocompleteChipsField extends BaseField {
  type: FormControls.AUTOCOMPLETE_CHIP;
  options: ViewValue[];
  creatable?: boolean;
  icon?: string;
}

export interface ChipInputField extends BaseField {
  type: FormControls.CHIP_INPUT;
}

export interface ToggleField extends BaseField {
  type: FormControls.TOGGLE;
  text: string;
}
export interface ChipsField extends BaseField {
  type: FormControls.CHIPS;
  multipe: boolean;
}

export interface GridAdd extends BaseField {
  type: FormControls.GRID_ADD;
  defaultValue: string[];
}

export interface Image extends BaseField {
  type: FormControls.IMAGE;
  defaultValue: string[];
}

export interface Hidden extends BaseField {
  type: FormControls.HIDDEN
}

export type fields = SelectField |
  TextField |
  TextArea |
  DateField |
  AutocompleteField |
  AutocompleteChipsField |
  ChipInputField |
  ToggleField |
  ChipsField |
  TextAreaAdd |
  GridAdd |
  Image |
  Hidden;
