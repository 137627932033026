const mainColors: any = {
  popular: {
    main: '#ECB5B5',
    border: '#e4b4b4',
  },
  decades: {
    main: '#8893f8',
    border: '#a9bbd4',
  },
  history: {
    main: '#8eb7ad',
    border: '#e3d6aa',
  },
  science: {
    main: '#6e8e9a',
    border: '#a3bfa9',
  },
  educate: {
    main: '#aecdd6',
    border: '#a7b3b7',
  },
  stories: {
    main: '#479ecf',
    border: '#96c2bc',
  },
  autobiographies: {
    main: '#eaf1fc',
    border: '#eaf1fc',
  },
  documentaries: {
    main: '#88caf8',
    border: '#b1b6c7',
  },
  'music & chat': {
    main: '#dbef8b',
    border: '#d6b4d9',
  },
  'travel & culture': {
    main: '#d7fb97',
    border: '#eed6dc',
  },
  spiritual: {
    main: '#f6b470',
    border: '#e3af9f',
  },
  'news & politics': {
    main: '#ec88f8',
    border: '#97c4c4',
  },
  'books & literature': {
    main: '#b7988e',
    border: '#bc958a',
  },
  'book club': {
    main: '#beb4f2',
    border: '#9088be',
  },
  sport: {
    main: '#d7bf4c',
    border: '#ca8fcc',
  },
  'crime & mysteries': {
    main: '#9ad5a6',
    border: '#c7b98b',
  },
  australiana: {
    main: '#c7d397',
    border: '#ccc0ab',
  },
  'dementia specific': {
    main: '#e4e9f1',
    border: '#a5b4cb',
  },
  entertainment: {
    main: '#efac99',
    border: '#7889A2',
  },
  nature: {
    main: '#61d2ac',
    border: '#998389',
  },
  politics: {
    main: '#ec88f8',
    border: '#B69986',
  },
  'business & technology': {
    main: '#c580b2',
    border: '#ADC0DE',
  },
  hobbies: {
    main: '#cec2f6',
    border: '',
  },
  people: {
    main: '#d47771',
    border: '',
  },
  seasons: {
    main: '#f8ec88',
    border: '',
  },
};

export const colorList = Object.keys(mainColors).map((key) => mainColors[key].main);