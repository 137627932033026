export const LANGUAGES = [
  {
    languageCode: 'AB',
    languageName: 'Abkhaz'
  },
  {
    languageCode: 'AA',
    languageName: 'Afar'
  },
  {
    languageCode: 'AF',
    languageName: 'Afrikaans'
  },
  {
    languageCode: 'AK',
    languageName: 'Akan'
  },
  {
    languageCode: 'SQ',
    languageName: 'Albanian'
  },
  {
    languageCode: 'AM',
    languageName: 'Amharic'
  },
  {
    languageCode: 'AR',
    languageName: 'Arabic'
  },
  {
    languageCode: 'AN',
    languageName: 'Aragonese'
  },
  {
    languageCode: 'HY',
    languageName: 'Armenian'
  },
  {
    languageCode: 'AS',
    languageName: 'Assamese'
  },
  {
    languageCode: 'AV',
    languageName: 'Avaric'
  },
  {
    languageCode: 'AE',
    languageName: 'Avestan'
  },
  {
    languageCode: 'AY',
    languageName: 'Aymara'
  },
  {
    languageCode: 'AZ',
    languageName: 'Azerbaijani'
  },
  {
    languageCode: 'BM',
    languageName: 'Bambara'
  },
  {
    languageCode: 'BA',
    languageName: 'Bashkir'
  },
  {
    languageCode: 'EU',
    languageName: 'Basque'
  },
  {
    languageCode: 'BE',
    languageName: 'Belarusian'
  },
  {
    languageCode: 'BN',
    languageName: 'Bengali'
  },
  {
    languageCode: 'BH',
    languageName: 'Bihari'
  },
  {
    languageCode: 'BI',
    languageName: 'Bislama'
  },
  {
    languageCode: 'BS',
    languageName: 'Bosnian'
  },
  {
    languageCode: 'BR',
    languageName: 'Breton'
  },
  {
    languageCode: 'BG',
    languageName: 'Bulgarian'
  },
  {
    languageCode: 'MY',
    languageName: 'Burmese'
  },
  {
    languageCode: 'CA',
    languageName: 'Catalan'
  },
  {
    languageCode: 'ZHH',
    languageName: 'Cantonese'
  },
  {
    languageCode: 'CH',
    languageName: 'Chamorro'
  },
  {
    languageCode: 'CE',
    languageName: 'Chechen'
  },
  {
    languageCode: 'ZH',
    languageName: 'Chinese'
  },
  {
    languageCode: 'CV',
    languageName: 'Chuvash'
  },
  {
    languageCode: 'RAR',
    languageName: 'Cook Islands Maori'
  },
  {
    languageCode: 'KW',
    languageName: 'Cornish'
  },
  {
    languageCode: 'CO',
    languageName: 'Corsican'
  },
  {
    languageCode: 'CR',
    languageName: 'Cree'
  },
  {
    languageCode: 'HR',
    languageName: 'Croatian'
  },
  {
    languageCode: 'CS',
    languageName: 'Czech'
  },
  {
    languageCode: 'DA',
    languageName: 'Danish'
  },
  {
    languageCode: 'NL',
    languageName: 'Dutch'
  },
  {
    languageCode: 'EN',
    languageName: 'English'
  },
  {
    languageCode: 'EO',
    languageName: 'Esperanto'
  },
  {
    languageCode: 'ET',
    languageName: 'Estonian'
  },
  {
    languageCode: 'EE',
    languageName: 'Ewe'
  },
  {
    languageCode: 'FO',
    languageName: 'Faroese'
  },
  {
    languageCode: 'FJ',
    languageName: 'Fijian'
  },
  {
    languageCode: 'FI',
    languageName: 'Finnish'
  },
  {
    languageCode: 'FIL',
    languageName: 'Filipino'
  },
  {
    languageCode: 'FR',
    languageName: 'French'
  },
  {
    languageCode: 'FF',
    languageName: 'Fula'
  },
  {
    languageCode: 'GD',
    languageName: 'Gaelic'
  },
  {
    languageCode: 'GL',
    languageName: 'Galician'
  },
  {
    languageCode: 'KA',
    languageName: 'Georgian'
  },
  {
    languageCode: 'DE',
    languageName: 'German'
  },
  {
    languageCode: 'EL',
    languageName: 'Greek'
  },
  {
    languageCode: 'KL',
    languageName: 'Greenlandic'
  },
  {
    languageCode: 'GN',
    languageName: 'Guaraní'
  },
  {
    languageCode: 'GU',
    languageName: 'Gujarati'
  },
  {
    languageCode: 'HT',
    languageName: 'Haitian'
  },
  {
    languageCode: 'HA',
    languageName: 'Hausa'
  },
    {
      languageCode: 'HAW',
      languageName: 'Hawaiian'
    },
  {
    languageCode: 'HE',
    languageName: 'Hebrew'
  },
  {
    languageCode: 'HZ',
    languageName: 'Herero'
  },
  {
    languageCode: 'HI',
    languageName: 'Hindi'
  },
  {
    languageCode: 'HO',
    languageName: 'Hiri Motu'
  },
  {
    languageCode: 'HU',
    languageName: 'Hungarian'
  },
  {
    languageCode: 'IS',
    languageName: 'Icelandic'
  },
  {
    languageCode: 'IO',
    languageName: 'Ido'
  },
  {
    languageCode: 'IG',
    languageName: 'Igbo'
  },
  {
    languageCode: 'A2',
    languageName: 'Indigenous'
  },
  {
    languageCode: 'ID',
    languageName: 'Indonesian'
  },
  {
    languageCode: 'IA',
    languageName: 'Interlingua'
  },
  {
    languageCode: 'IE',
    languageName: 'Interlingue'
  },
  {
    languageCode: 'IU',
    languageName: 'Inuktitut'
  },
  {
    languageCode: 'IK',
    languageName: 'Inupiaq'
  },
  {
    languageCode: 'GA',
    languageName: 'Irish'
  },
  {
    languageCode: 'IT',
    languageName: 'Italian'
  },
  {
    languageCode: 'JPN',
    languageName: 'Japanese'
  },
  {
    languageCode: 'JV',
    languageName: 'Javanese'
  },
  {
    languageCode: 'JAM',
    languageName: 'Jamaican Patois'
  },
  {
    languageCode: 'KN',
    languageName: 'Kannada'
  },
  {
    languageCode: 'KR',
    languageName: 'Kanuri'
  },
  {
    languageCode: 'KS',
    languageName: 'Kashmiri'
  },
  {
    languageCode: 'KK',
    languageName: 'Kazakh'
  },
  {
    languageCode: 'KM',
    languageName: 'Khmer'
  },
  {
    languageCode: 'KI',
    languageName: 'Kikuyu'
  },
  {
    languageCode: 'RW',
    languageName: 'Kinyarwanda'
  },
  {
    languageCode: 'RN',
    languageName: 'Kirundi'
  },
  {
    languageCode: 'KV',
    languageName: 'Komi'
  },
  {
    languageCode: 'KG',
    languageName: 'Kongo'
  },
  {
    languageCode: 'KO',
    languageName: 'Korean'
  },
  {
    languageCode: 'KJ',
    languageName: 'Kuanyama'
  },
  {
    languageCode: 'KU',
    languageName: 'Kurdish'
  },
  {
    languageCode: 'KY',
    languageName: 'Kyrgyz'
  },
  {
    languageCode: 'LO',
    languageName: 'Lao'
  },
  {
    languageCode: 'LA',
    languageName: 'Latin'
  },
  {
    languageCode: 'LV',
    languageName: 'Latvian'
  },
  {
    languageCode: 'LI',
    languageName: 'Limburgish'
  },
  {
    languageCode: 'LN',
    languageName: 'Lingala'
  },
  {
    languageCode: 'LT',
    languageName: 'Lithuanian'
  },
  {
    languageCode: 'LU',
    languageName: 'Luba-Katanga'
  },
  {
    languageCode: 'LG',
    languageName: 'Luganda'
  },
  {
    languageCode: 'LB',
    languageName: 'Luxembourgish'
  },
  {
    languageCode: 'MK',
    languageName: 'Macedonian'
  },
  {
    languageCode: 'MG',
    languageName: 'Malagasy'
  },
  {
    languageCode: 'MS',
    languageName: 'Malay'
  },
  {
    languageCode: 'ML',
    languageName: 'Malayalam'
  },
  {
    languageCode: 'DV',
    languageName: 'Maldivian'
  },
  {
    languageCode: 'MT',
    languageName: 'Maltese'
  },
  {
    languageCode: 'CMN',
    languageName: 'Mandarin'
  },
  {
    languageCode: 'GV',
    languageName: 'Manx'
  },
  {
    languageCode: 'MI',
    languageName: 'Maori'
  },
  {
    languageCode: 'MR',
    languageName: 'Marathi'
  },
  {
    languageCode: 'MH',
    languageName: 'Marshallese'
  },
  {
    languageCode: 'MFE',
    languageName: 'Mauritian Creole'
  },
  {
    languageCode: 'MN',
    languageName: 'Mongolian'
  },
  {
    languageCode: 'NA',
    languageName: 'Nauru'
  },
  {
    languageCode: 'NV',
    languageName: 'Navajo'
  },
  {
    languageCode: 'NG',
    languageName: 'Ndonga'
  },
  {
    languageCode: 'NE',
    languageName: 'Nepali'
  },
  {
    languageCode: 'ND',
    languageName: 'North Ndebele'
  },
  {
    languageCode: 'SE',
    languageName: 'Northern Sami'
  },
  {
    languageCode: 'NO',
    languageName: 'Norwegian'
  },
  {
    languageCode: 'II',
    languageName: 'Nuosu'
  },
  {
    languageCode: 'NY',
    languageName: 'Nyanja'
  },
  {
    languageCode: 'OC',
    languageName: 'Occitan'
  },
  {
    languageCode: 'OJ',
    languageName: 'Ojibwe, Ojibwa'
  },
  {
    languageCode: 'CU',
    languageName: 'Old Slavonic'
  },
  {
    languageCode: 'OR',
    languageName: 'Oriya'
  },
  {
    languageCode: 'OM',
    languageName: 'Oromo'
  },
  {
    languageCode: 'OS',
    languageName: 'Ossetian'
  },
  {
    languageCode: 'PI',
    languageName: 'Pāli'
  },
  {
    languageCode: 'PS',
    languageName: 'Pashto'
  },
  {
    languageCode: 'FA',
    languageName: 'Persian'
  },
  {
    languageCode: 'PL',
    languageName: 'Polish'
  },
  {
    languageCode: 'PT',
    languageName: 'Portuguese'
  },
  {
    languageCode: 'PA',
    languageName: 'Punjabi'
  },
  {
    languageCode: 'QU',
    languageName: 'Quechua'
  },
  {
    languageCode: 'RO',
    languageName: 'Romanian'
  },
  {
    languageCode: 'RM',
    languageName: 'Romansh'
  },
  {
    languageCode: 'RU',
    languageName: 'Russian'
  },
  {
    languageCode: 'SM',
    languageName: 'Samoan'
  },
  {
    languageCode: 'SG',
    languageName: 'Sango'
  },
  {
    languageCode: 'SA',
    languageName: 'Sanskrit'
  },
  {
    languageCode: 'SC',
    languageName: 'Sardinian'
  },
  {
    languageCode: 'SR',
    languageName: 'Serbian'
  },
  {
    languageCode: 'SN',
    languageName: 'Shona'
  },
  {
    languageCode: 'SD',
    languageName: 'Sindhi'
  },
  {
    languageCode: 'SI',
    languageName: 'Sinhalese'
  },
  {
    languageCode: 'SK',
    languageName: 'Slovak'
  },
  {
    languageCode: 'SL',
    languageName: 'Slovene'
  },
  {
    languageCode: 'SO',
    languageName: 'Somali'
  },
  {
    languageCode: 'NR',
    languageName: 'South Ndebele'
  },
  {
    languageCode: 'ST',
    languageName: 'Southern Sotho'
  },
  {
    languageCode: 'ES',
    languageName: 'Spanish'
  },
  {
    languageCode: 'SU',
    languageName: 'Sundanese'
  },
  {
    languageCode: 'SW',
    languageName: 'Swahili'
  },
  {
    languageCode: 'SS',
    languageName: 'Swati'
  },
  {
    languageCode: 'SV',
    languageName: 'Swedish'
  },
  {
    languageCode: 'TL',
    languageName: 'Tagalog'
  },
  {
    languageCode: 'TY',
    languageName: 'Tahitian'
  },
  {
    languageCode: 'TG',
    languageName: 'Tajik'
  },
  {
    languageCode: 'TA',
    languageName: 'Tamil'
  },
  {
    languageCode: 'TT',
    languageName: 'Tatar'
  },
  {
    languageCode: 'TE',
    languageName: 'Telugu'
  },
  {
    languageCode: 'TET',
    languageName: 'Tetum'
  },
  {
    languageCode: 'TH',
    languageName: 'Thai'
  },
  {
    languageCode: 'BO',
    languageName: 'Tibetan'
  },
  {
    languageCode: 'TI',
    languageName: 'Tigrinya'
  },
  {
    languageCode: 'TO',
    languageName: 'Tongan'
  },
  {
    languageCode: 'TS',
    languageName: 'Tsonga'
  },
  {
    languageCode: 'TN',
    languageName: 'Tswana'
  },
  {
    languageCode: 'TR',
    languageName: 'Turkish'
  },
  {
    languageCode: 'TK',
    languageName: 'Turkmen'
  },
  {
    languageCode: 'TW',
    languageName: 'Twi'
  },
  {
    languageCode: 'UK',
    languageName: 'Ukrainian'
  },
  {
    languageCode: 'UR',
    languageName: 'Urdu'
  },
  {
    languageCode: 'UG',
    languageName: 'Uyghur'
  },
  {
    languageCode: 'UZ',
    languageName: 'Uzbek'
  },
  {
    languageCode: 'VE',
    languageName: 'Venda'
  },
  {
    languageCode: 'VI',
    languageName: 'Vietnamese'
  },
  {
    languageCode: 'VO',
    languageName: 'Volapük'
  },
  {
    languageCode: 'WA',
    languageName: 'Walloon'
  },
  {
    languageCode: 'CY',
    languageName: 'Welsh'
  },
  {
    languageCode: 'FY',
    languageName: 'Western Frisian'
  },
  {
    languageCode: 'WO',
    languageName: 'Wolof'
  },
  {
    languageCode: 'XH',
    languageName: 'Xhosa'
  },
  {
    languageCode: 'YI',
    languageName: 'Yiddish'
  },
  {
    languageCode: 'YO',
    languageName: 'Yoruba'
  },
  {
    languageCode: 'YU',
    languageName: 'Yugoslavia'
  },
  {
    languageCode: 'ZA',
    languageName: 'Zhuang'
  }
];